@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@600&display=swap');


.svgbuttonheight {
  height: 20px !important;
}

button.delete.btn.btn-primary {
  background: red !important;
}


.cwf {
  color: white !important;
  font-weight: 500;
}

 
.finder{
  border: 1px solid #00000040;
    border-radius: 5px;
    background: #164194;
    width: 38px;
    box-shadow: 1px 1px 20px 5px #00000042;
}
.flex.between {
  display: flex;
  justify-content: flex-start;
}

button.downloadidea:hover {
  background: green;
  color: white; 
   font-weight: 600;

}
button.downloadidea {
  background: #0080002e;
  color: green;
  border: none;
  border-radius: 5px;
  box-shadow: 1px 1px 1px 1px #00000045;
  transition: 0.3s;
  font-weight: 600;
}
a.btn.btn-sm.ertere {
  background: #6e6ed6;
  width: 100px;
  height: 24px;
}

.login1 {
  line-height: 48px;
  position: relative;
  /* border: 2px solid #e0e0e0; */
  border-top: 10px solid #f1f5f9;
  background-color: #ffffffa6;
  width: 130%;
  /* height: 30vh; */
  /* padding: 10px 10px; */
  left: -106px;
  top: -107px;
  /* display: flex; */
  /* width: 479px; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* border-radius: 94px; */
  box-shadow: 0px 1px 5px 4px #00000017;
  border-bottom-left-radius: 42px;
  border-top-right-radius: 44px;
}

.table>thead {
  font-size: 12px;
  vertical-align: bottom;
}

#root>div>div>div.cccc>div.hhh>div.children>div>div>div.d-flex.bd-highlight>div.p-2.w-100.bd-highlight>a>button {
  height: 32px;
  width: 137px;
  /* width: 107px; */
  color: #f1f2f1;
  background: #b8d7ff;
  font-weight: 500 !important;
  border: none;
  border-radius: 5px;
  box-shadow: 1px 1px 4px 1px #0000005c;
  /* background: #164194 ; */
  background: linear-gradient(170deg, hsl(227deg 74% 49% / 70%) 0%, hsl(220deg 74% 33% / 81%) 49%, hsl(226deg 74% 35% / 90%) 89%) !important;
  position: relative;
  top: -59px;
  font-weight: 500 !important;
}

#root>div>div>div.cccc>div.hhh>div.children>div>div>div.d-flex.bd-highlight>div.p-2.w-100.bd-highlight {
  display: flex;
  justify-content: end;
}

#root>div>div>div.cccc>div.hhh>div.children>div>div>div.card-body>div>div:nth-child(1)>div:nth-child(1)>div {
  margin-top: -89px;
  font-size: 13px;
}

#root>div>div>div.cccc>div.hhh>div.children>div>div>div.card-body>div>div:nth-child(1)>div:nth-child(1)>div>label>select {
  width: 41px;
  height: 35px;
  font-size: 12px;
  padding: 8px;
  position: relative;
  left: 5px;
}

#root>div>div>div.cccc>div.hhh>div.children>div>div>div.card-body>div>div:nth-child(1)>div:nth-child(2)>div {
  margin-top: -85px;

}

#root>div>div>div.cccc>div.hhh>div.children>div>div>div.card-body>div>div:nth-child(1)>div:nth-child(2)>div>input {
  width: 197px;
  border-radius: 6px;
  padding: 5px !important;
}


.sc-eDWCr.hbotQl {
  font-size: 12px;
}

tr {
  vertical-align: middle;
}

.card {
  border: none;
  border-radius: 9px;
  font-size: 13px;
  box-shadow: 1px 1px 7px 1px #00000030;
  /* padding: 0px 10px; */
}

.dataTables_paginate {
  display: flex;
  justify-content: end;
}

#root>div>div>div.cccc>div.hhh>div.children>div>div>div.card-body>div>div:nth-child(3)>div.col-sm-12.col-md-7>div>ul>li:nth-child(1)>a {
  /* height: 36px; */
  font-size: 9px;
  top: 1px;
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
  position: relative;
  /* top: -5px; */
}

li.disabled.page-item {
  position: relative;
  /* top: 5px; */
}



.p-2.flex-shrink-1.bd-highlight {
  position: relative;
  top: -55px;
}

.bty {
  /* width: 98%; */
  /* background: #6d6dff; */
  color: white;
  font-weight: 500 !important;
  margin-left: 62px !important;
  width: 295px !important;
}

.logovm {
  display: flex;
  justify-content: center;
  position: relative;
  top: -134px;
  /* left: 34px; */
}

.login {
  padding: 220px 50px;
  height: 100vh;
}

.login2 {
  color: #164194;
  font-size: 25px;
  text-align: center;
  font-weight: 600 !important;

}

/* .btn{
  width: 100%;
} */
h3 {
  font-size: 19px;
  padding: 10px 10px;
  font-weight: 600;
  border-bottom: 6px solid rgb(236, 236, 236);
  /* background-color: rgb(235, 235, 235); */
  color: green;
}

.cname {
  font-size: 15px;
  font-weight: 500 !important;
}

.cname1 {
  font-size: 17px;
  font-weight: 500 !important;
}

.cname2 {
  font-size: 15px;
  font-family: sans-serif;
}

.pubutton {
  font-size: 20px;
  padding: 0px 10px;
}

#pb {
  color: red;
}

#pb1 {
  color: green;
}

#rowname {
  border-bottom: 1px solid rgb(236, 236, 236);
  padding: 5px 0px;

}

#rowname:hover {
  background-color: white;
}

.loginimg {
  border: 1px solid #e0e0e0;
  background-color: white;
  text-align: center;
  padding: 10px;
}

.navbar {
  border: none;
  background: #f3f2f2;
  position: fixed;
  width: 99%;
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 12px;
  border: 1px solid rgba(209, 213, 219, 0.3);
  box-shadow: 1px 1px 1px #00000017;
  z-index: 1;
  /* left: 295px; */
  top: 11px;
  height: 51px;
}

.app {
  /* background-color: #5c61f2; */
  width: 100%;
  height: 100vh;
  /* position: fixed; */
  /* overflow: scroll; */
}

.prmotee {
  height: 53px;
  width: 5.25em !important;
  transform-origin: center;
  animation: rotate4 2s linear infinite;


}

.circle {
  fill: none;
  stroke: hsl(214, 97%, 59%);
  stroke-width: 2;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 1.5s ease-in-out infinite;
}

@keyframes rotate4 {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash4 {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dashoffset: -125px;
  }
}


svg {
  cursor: pointer;
}

@media only screen and (max-width: 700px) {
  .app {
    position: relative;
    /* overflow: scroll; */
    background-color: #f1f5f9;
    overflow-y: scroll;
  }
}

@media only screen and (max-height: 700px) {
  .app {
    position: relative;
    /* overflow: scroll; */
    background-color: #f3f2f2;
    overflow-y: scroll;
  }
}

svg.color {
  /* display: none; */
}

nav.navbar {
  /* box-shadow: 1px 1px 12px #00000045; */
}

.sidebar {
  /* box-shadow: 1px 1px 4px #00000045; */
  /* box-shadow: 1px 1px 8px 1px #0000009c; */
}

.search {
  display: flex;
  width: 60%;
  /* justify-content: flex-end; */
}

#search2 {
  /* border-radius: 12px; */
  box-shadow: none;
  width: 48%;
  box-sizing: border-box;
  border: none;
  /* border-bottom: 1px solid #dddddd; */
  font-size: 13px;
  background-color: none;
  /* background-image: url(searchicon.png); */
  background-position: 10px 10px;
  background-repeat: no-repeat;
  /* padding: 12px 20px 12px 40px; */
  outline: none;
  border-bottom-right-radius: 24px;
  border-top-left-radius: 16px;
  box-shadow: 1px 1px 11px #0000003b;
  position: relative;
  /* left: 372px; */
}

/* .form-control{
    background: #fff;
    font-size: 17px;
    border-radius: 50px;
    padding: 6px 4px 9px 35px;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 10px;
  } */
.full {
  font-size: 22px;
  cursor: pointer;
  margin-right: 20px;
}

input.form-control.form-control.form-control-sm {
  position: relative;
  top: 11px;
  height: 12px;
}

.form-label {
  font-style: sans;
  font-weight: 500 !important;
  margin-bottom: 0.5rem;
  font-size: 12px !important;
  color: #000000c7;
}




#formBasicName {
  font-size: 12px;
}

.card-header.card-title {
  border-bottom: 1px solid #16419440 !important;
  background: #ffffff !important;
  color: #164194 !important;
  font-weight: 600;
  height: 47px;
  font-size: 16px;
}

.card.card-body {
  border-top: none;
}

/* input:-internal-autofill-selected {
 
  background-image: none !important;
  background-color:white!important;
 
} */


.logo111 {
  width: 300px;

}

.email {

  font-size: 22px;
  cursor: pointer;
  margin-right: 20px;
  color: green;
  position: relative;
  top: 14px;
  background: white;
  height: 28px;
  width: 24px;
  padding: 2px;
  border-radius: 5px;
  top: 5px
}

.bell {
  display: block;
  font-size: 22px;
  cursor: pointer;
  margin-right: 20px;
  color: red;
  position: relative;
  top: 0px;
}

.user {
  font-size: 22px;
  cursor: pointer;
  margin-right: 20px;
  font-size: 15px;
}

.lg {
  width: 10%;
}

p1 {
  font-size: 13px;
  color: grey;
}

p2 {
  font-size: 13px;
}

.img2 {
  margin-top: -5px;
}

.img3 {
  margin-top: -5px;
}

.main {
  width: 100%;
  padding: 20px;
  position: fixed;

}

.sr-only {
  display: none;
}

a.page-link.page-link {
  background: #F3F6F9;
  color: black;
  font-size: 9px;
}

.upperbar {
  height: 1px;
  border-right: 1px solid #e8e8e8;
  color: #2d2df2;
  margin-top: -66px;
  width: 70px;
  background: white;
  height: 1px !important;
  z-index: 9999;
  position: absolute;
}

.sidebar {
  overflow: hidden;
  /* margin-top: -80px; */
  width: 70px;
  /* height: 100%; */
  z-index: 10;
}

.sidebar:hover {
  /* padding-top: 10px; */
  /* width: 220px!important; */
}

.sidebar {
  padding-top: 10px;
  background-color: #164194;
  color: black;


  /* height: 100vh; */
  /* transition: all 0.5s; */
}

th {}

.redd {
  padding: 12px 12px;
}

.sidebar {
  position: fixed;
  height: 100%;
  left: 1px;
  top: 0px;
  background: #fff;
  box-shadow: 0 0 20px rgba(8, 21, 66, 0.05) !important;
  z-index: 0;
}

.sidebar::-webkit-scrollbar {
  width: 10px;
}


.top_section {
  display: flex;
  text-align: center;
  padding: 20px 15px;
  width: 220px;
  cursor: pointer;
}

.logo {
  font-size: 25px;
  padding: 0px;
}

.bars {
  font-size: 25px;
  display: flex;
  margin-left: 30px;
}

.link {
  line-height: 27px;
  font-size: 14px;
  fill: #164194;
  stroke: #ffffff;
  left: 11px;
  position: relative;
  display: flex;
  color: rgb(0 0 0 / 68%);
  padding: 6px 6px;
  gap: 20px;
  width: 202px;
  transform: all 0.5s;
  cursor: pointer;
  top: 40px;
  text-decoration: none;
  font-size: 12px;
  font-weight: 600 !important;
}

.link:hover {
  font-weight: bold;
  background-color: #e9e9e991;
  color: black;
  transform: all 0.5s;
  border-radius: 6px;
  color: black;
  stroke: #ffffff;
  fill: black;
}

td {
  font-size: 13px;
  line-height: 21px;
  text-align: center;
}

th {
  text-align: center;
  background-color: #164194;
  /* color: white; */
  padding: 12px 12px;
}

thead.p-3.mb-2.bg-success.text-white {
  background: #164194 !important;
}




svg.feather {
  position: relative;
  height: 27px;
  left: -5px;
  padding: 3px;
  border-radius: 4px;
  top: 0px;
  width: 30px;
  /* stroke: white; */
  font-variant: historical-ligatures;
  /* fill: #164194; */
  box-shadow: 1px 1px 20px 1px #0000ff29;
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
  border-radius: 5px;
}







.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: white;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-button:focus {
  z-index: 3;
  border-color: none;
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

input#inlineFormInputGroupUsername2 {
  box-shadow: none;
  outline: none;
  margin-block: 23px;
}

.card {
  /* border-top-left-radius: 23px; */
  /* border-bottom-right-r        mmmmmmmmmmmmmmmmmmmm                                                                                                                                                                                                                                                                                                                                                                                                                                                                   ṁadius: 23px; */
}

.active {
  /* font-weight: bolder; */
  /* font-weight: bolder; */
  background-color: #F3F6F9;
  color: rgb(0 0 0) !important;
  transform: all 0.5s;
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  color: #000000;
  fill: #164194;
  stroke: white;
  font-weight: 700 !important;
  width: 196px !important;
}

.aeroplan {
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
}

.rtysdr {
  position: relative;
  top: 12px;
}

.lesser {
  border: none !important;
}

.icon,
.link_text {
  font-size: 15px;
  padding: 3px 0px;
}

.btbt {}

.icon1 {
  background-color: #efefef;
  color: #0d6efd;
  border-radius: 30%;
  padding: 2px 6px;
}

.card1 {
  border: none;
  width: 100%;
  height: 100px;
  background: #f8f8f8;
  margin-left: 10px;
  padding: 10px;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  fill: #164194;
  height: 17px;
}

.card1 p3 {

  font-size: 35px;
  color: #494848;
  font-weight: bold;
  line-height: 30px;
  padding-left: 10px;
  margin-top: 10px;
  display: inline-block;

}

.card1 p3 span {
  font-size: 20px;
  font-weight: 400 !important;
  /* color: #bcbfc3; */
}

.box-icon {
  font-size: 40px !important;
  float: right;
  margin-top: 20px !important;
  color: #bcbfc3;
  padding-right: 10px
}

.card2 {
  border-radius: 8px;
  border: none;
  /* width: 60%; */
  height: 400px;
  background: #f8f8f8;
  margin-left: 10px;
  padding: 10px;
  margin-top: 20px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

.card3 {
  border: 1px solid rgb(243, 237, 237);
  width: 102%;
  height: 400px;
  background: rgb(248, 248, 248);

  padding: 10px;
  margin-top: 20px;
  cursor: pointer;
}

.card3 {
  border-radius: 19px;
  border: none;
  background: rgb(248, 248, 248);

  box-shadow: 1px 1px 6px #0000004f;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgb(255, 255, 255);
  color: black;
  text-align: center;
  padding: 20px;
}

.product {
  border: 1px solid rgb(243, 237, 237);
  background-color: white;
  height: auto;
  padding: 20px;

}

.product2 {
  /* border: 1px solid rgb(243, 237, 237); */
  background-color: white;
  height: auto;
  padding: 20px;

}

.product1 {
  border: 1px solid rgb(243, 237, 237);
  background-color: white;
  height: auto;
  padding: 20px;
}

@media only screen and (max-height: 580px) {
  .product2 {
    /* background-color: rgb(216, 22, 64); */
    /* width: 110%; */
    /* overflow-y: scroll; */
    position: relative;
    /* height: 95vh; */
  }
}

@media only screen and (max-width: 1380px) {
  .product1 {
    /* background-color: rgb(216, 22, 64); */
    /* width: 110%; */
    /* overflow: scroll; */
    position: relative;
    /* width: 90%; */
    /* height: 95vh; */
  }
}

@media only screen and (max-height: 1280px) {
  .product1 {
    /* background-color: rgb(216, 22, 64); */
    /* width: 110%; */
    /* overflow-y: scroll; */
    position: relative;
    /* height: 80vh; */
  }
}

.form-control {
  box-shadow: 1px 0px 2px #24232324;
  padding: 8px;
}

.btn-primary {
  background-color: #0d6efd;
  width: 121px;
  box-shadow: 1px 1px 5px #0000006b;
}


@media only screen and (max-width: 1580px) {
  .product2 {
    /* background-color: rgb(216, 22, 64); */
    /* width: 110%; */
    /* overflow: scroll; */
    position: relative;
    /* width: 90%; */
    /* height: 95vh; */
  }
}

@media only screen and (max-width: 780px) {
  .purchase {
    /* background-color: rgb(216, 22, 64); */
    /* width: 110%; */
    overflow: scroll;
    position: relative;
    width: 90%;
  }
}

.purchase {
  padding: 0px 10px;
}

h1 {
  /* border-bottom: 1px solid #16409423 ; */
  font-size: 18px;
  padding: 10px 15px;
  text-align: left;
  color: #000000;
  /* background: #ebf5ff; */
  /* color: #3088dd; */
  font-weight: 600 !important;
  height: 47px;
  font-size: 16px;
  /* border-bottom: 1px solid rgb(240, 236, 236); */
  /* background-color: #0070ff; */
  /* background: radial-gradient(#023af9, #5b71fb); */
  /* background: radial-gradient(#4C90D2, #0081ff); */
  /* border-radius: 5px; */
}

/* thead{ */
/* background: radial-gradient(#023af9, #5b71fb); */
/* background: radial-gradient(#4C90D2, #0081ff); */
/* color: #4C90D2; */
thead {
  border: none;
  background: #F3F6F9;
  /* background: radial-gradient(#023af9, #5b71fb); */
  /* background: radial-gradient(#3088dd, #5baaf5); */
  color: #ffffff;
  /* background: #4c90d2ad; */
}

th {
  border: none;
}


/* } */
@media only screen and (max-width: 1380px) {
  h1 {

    /* width: 90%; */

  }
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #ffffff;
}

.stable {
  border: 1px solid #e0e0e0;
  background: white;
  padding: 0px 30px;
}

.rong {
  position: relative;
  left: 152px;
}

.center {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 750px) {
  .stable {
    /* background-color: rgb(216, 22, 64); */
    /* width: 110%; */
    /* overflow-x: scroll; */
    /* position: relative; */
    /* height: 80vh; */
    width: 85%;
  }
}

.styled-table {
  border-collapse: collapse;
  margin: 20px;
  margin-left: 0px;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  margin-left: 8px;
}

.styled-table thead tr {
  /* background-color: #009879; */
  color: #ffffff;
  text-align: left;
  background: radial-gradient(#3088dd, #5baaf5);
}

@media only screen and (max-width: 750px) {
  .tttt {
    overflow-x: auto;
    overflow-y: hidden;
    text-align: justify;
    scrollbar-color: rgb(228, 228, 228) white;
  }
}

.tttt {
  overflow-x: auto;
  overflow-y: hidden;
  text-align: justify;
  scrollbar-color: rgb(228, 228, 228) white;
}

.book {
  position: relative;
  top: 12px;
}

.color {
  fill: #ffffff;
  height: 30px;
  width: 31px;
  position: fixed;
  top: 24px;
  stroke-width: 3px;
  left: 18px;
  background: linear-gradient(228deg, hsla(227, 74%, 49%, 1) 0%, hsla(220, 74%, 33%, 1) 49%, hsla(226, 74%, 35%, 1) 89%);
  padding: 4px;
  border-radius: 5px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.logoopen {
  position: relative;
  top: 38px;
  left: 32px;
}

.left {
  height: 16px;
  position: relative;
  top: -23px;
  left: 4px;
  fill: #2f7bff;
}

.rere {
  /* justify-content: end; */
  position: relative;
  top: 14px;
  left: 10px;
}

.btn.btn-sm.btn-primary.timer {

  top: 38px;
  position: relative;
  /* left: -165px; */
  width: 49px;

}

table.styled-table {
  position: relative;
  /* top: -37px; */
  left: 90px;
}

.timer {
  width: 60px;
}

.btn-primary {
  background: #164194;
  /* background-color: #0d6efd; */
  width: 121px;
  box-shadow: 1px 1px 5px #0000006b;
  border: none;
}

/* button.btn.btn-primary {
  background-color:  #164194!important ; */
/* height: 30px; */
/* font-size: 13px;
} */
button.btn.btn-primary {
  background: linear-gradient(170deg, hsl(227deg 74% 49% / 70%) 0%, hsl(220deg 74% 33% / 81%) 49%, hsl(226deg 74% 35% / 90%) 89%) !important;
  /* height: 30px; */
  font-size: 11px;
  border-radius: 5px;
  font-weight: 600;
}

/* .form-label {
  font-style: sans;
  font-weight: 500!important;
  margin-bottom: 0.5rem;
  font-size: 12px!important;
  color: #00000073;
  margin-top: 12px!important;
} */

.form-label {
  font-style: sans;
  font-weight: 500 !important;
  margin-bottom: 0.5rem;
  font-size: 12px !important;
  color: #000000ad;
  margin-top: 15px !important;
}


svg {
  height: 13px;
}

.styled-table th,
.styled-table td {

  /* padding: 12px 56px; */
  padding: 8px 20px;
  position: relative;
  top: 0px;
  z-index: 00;
  left: 0px;

}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}




.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

.hh {

  box-shadow: none;
  background: white;
  border-radius: 6px;
  border: none;

}

.select {
  position: relative;
  top: 86px;
}

.bilo {
  /* display: none; */
  background: #ffffff;
  position: relative;
  vertical-align: middle;
  top: 4px;
  padding: 2px;
  height: 26px;
  left: 19px;
  fill: #ff9f9f;
  stroke: #b70000;
}

svg.feather.feather-mail {
  position: relative;
  top: -7px;
  left: -2px;
}

.colorname {
  z-index: 9999;
  font-weight: bold;
  background: #164194;
  /* background: red; */
  text-decoration: none;
  color: #ffffff;
  height: 36px;
  width: 38px;
  padding-left: 13px;
  padding-top: 16px;
  border-radius: 33px;
  font-size: 16px;
  position: relative;
  top: 0px;
}

.ritu {
  width: 48%;
  position: relative;
  top: 30px;
  left: 8px;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;900&family=Overpass:ital,wght@0,100;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,700&display=swap');

* {
  font-family: 'Montserrat', sans-serif;
  /* font-family: 'Poppins', sans-serif; */
}

.book11 {
  width: 51%;
  height: 280px;
  position: relative;
  top: 29px;
}


.sc-hLBbgP.sc-bqWxrE.iDiWmI.MHRqX.rdt_TableCol {
  background: #164194;
  color: white;
}

input#inlineFormInputGroupUsername2 {
  border-bottom: 1px solid #00000047;
  border-radius: 0px;
}

.qtyn {
  width: 372px;
  /* position: relative; */
  /* left: 174px; */
  /* border: 1px solid black; */
  position: relative;
  left: 120px;

}

.netn {
  width: 82px;
  /* position: relative; */
  /* left: 174px; */
  /* border: 1px solid black; */
  position: relative;
  left: 286px;
}


.kgg {
  position: relative;
  left: -2px;
}


.gstn {
  width: 368px;
  /* position: relative; */
  /* left: 174px; */
  /* border: 1px solid black; */
  position: relative;
  left: 165px;
}

.gross {
  position: relative;
  left: 61px;


}

td.total {
  position: relative;
  left: 7px;
}

.namee {
  width: 245px;
  border-bottom: 1px solid white
}


/* .qty1{
background-color: white !important;
color: black !important;
  position: relative;
      left: 159px;
      z-index: 9999999;
  }
  
  .qty2{
  position: relative;
      left: 47px;
      background-color: white !important;
      color: black !important;
      z-index: 9999999;
  }
  
  .gross{
      position: relative;
      left: 195px;
      background-color: white !important;
color: black !important;
z-index: 9999999;
  }
  
  
  .net{background-color: white !important;
    color: black !important;
      position: relative;
      left: 185px;
      z-index: 9999999;
  }
  
  
  
  .gst{background-color: white !important;
    color: black !important;
  position: relative;
      left: 98px;
      z-index: 9999999;
  }
  
  
  .total{background-color: white !important;
    color: black !important;
      position: relative;
      left: 11px;
      z-index: 9999999;
  
  } */


.qty1 {

  position: relative;
  left: 188px;

}

.qty2 {
  position: relative;
  left: 110px;

}
.gross {
  position: relative;
  left: 96px;
}

.net {
  position: relative;
  left: 185px;

}



.gst {
  position: relative;
  left: 98px;

}


.total {
  position: relative;
  left: 11px;

}

th.bold {
  color: black;
}


.dip {
  background: #164194;
  border-radius: 15px;
  height: 39px;
  box-shadow: 1px 1px 7px #0000003b;
  display: flex;
  justify-content: center;
}

.firstcss {
  border-right: 1px solid black;
  font-size: 21px;
  font-weight: bolder;
  text-align: center;
}

.textalgin {
  text-align: center;
  font-size: 9px;
  vertical-align: text-bottom;
  position: relative;
  /* top: 16px; */
  font-weight: 900;
  height: 1px;
}

.bb {
  border-bottom: 1px solid black;
}

.bb2 {
  width: 51%;
  height: 50px;
  position: relative;
  padding: 6px;
}

.cc1 {
  position: relative;
  top: 11px;
  border: 1px solid black;
  /* margin: 20px;
    padding: 20px; */
}

.ss1 {
  display: flex;
  justify-content: center;
}

.brbb {
  border-bottom: 1px solid black;
}

.rr {
  position: relative;
  top: 9px;
}

.ff {
  font-size: 17px;
  font-weight: 600 !important;
}

.ret {

  text-align: center;
  border-top: 1px solid black;
  border-bottom: 1px solid black;

}

.bbbb {
  border-right: 1px solid black;
}

span.prrot {
  font-size: 10px;
  font-weight: 900;
}

.ert {
  border-top: 1px solid black;
}

.rty {
  border-top: 1px solid;
}

.wert {
  border-bottom: 1px solid;
}

td.th {
  border-right: 1px solid;
}

td.th {
  font-size: 8px;
  font-weight: 800;
  border-bottom: 1px solid;
}


.wer {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.images {
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 5px;
  padding: 3px;
}

img.prrer {
  position: relative;
  top: 38px;
  left: 10px;
  z-index: 999999;
}

.top_section {
  display: flex;
  text-align: center;
  padding: 20px 15px;
  width: 220px;
  cursor: pointer;
  height: 53px;
  /* border-bottom: 1px solid #ffffff69; */
}

.logoopen {
  position: fixed;
  top: 13px;
  left: 117px;
}

/* .active {
  font-weight: bolder;
  /* border: 2px solid #0d6efd3b; */
/* background-color: rgb(255 255 255 / 34%);
  color: rgb(0, 0, 0);
  transform: all 0.5s; */
/* border-radius: 9px; */
/* color: #fcfdff;
  fill: #164194;
  stroke: #ffffff; */
/* margin-block: 6px; */
/* height: 43px; */
/* } */



td {
  font-size: 12px;
  line-height: 13px;
}


a.link.j2j {
  margin-block: 26px;
  top: 9px;
}

.ffff {
  background-color: #164094df
}


.qw .er {
  background: linear-gradient(228deg, hsla(227, 74%, 49%, 1) 0%, hsla(220, 74%, 33%, 1) 49%, hsla(226, 74%, 35%, 1) 89%) !important;
  /* background-color: rgb(216, 22, 64); */
  /* overflow-y: scroll; */
  /* overflow-x: scroll; */
  position: fixed;
  height: 100%;
  /* height: 518px; */
  /* border-bottom-left-radius: 36px; */
  /* border-radius: 16px; */
  left: 1px;
  top: 0px;
  background: linear-gradient(228deg, hsla(227, 74%, 49%, 1) 0%, hsla(220, 74%, 33%, 1) 49%, hsla(226, 74%, 35%, 1) 89%);
  /* background: linear-gradient(352deg, rgba(22,65,148,0.9668242296918768) 22%, rgba(22,50,148,1) 44%, rgba(32,73,217,1) 100%); */
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  width: 60px;
}


.qw.er {
  position: fixed;
  height: 102%;
  background: linear-gradient(228deg, hsla(227, 74%, 49%, 1) 0%, hsla(220, 74%, 33%, 1) 49%, hsla(226, 74%, 35%, 1) 89%) !important;
overflow: scroll;
  /* background: rgb(255, 255, 255); */
  z-index: 00;
  width: 75px;
}

thead {
  border: none;
  background: #F3F6F9 !important;
  color: #000000;
}

.header {
  display: flex;
  justify-content: center;
}

svg.feather.feather-user {
  position: relative;
  left: -78px;
}

li#threefour {
  z-index: 00000;
  box-shadow: none;
  transition: 0.5s;
}

li#threefour:hover {
  background: #164194;
  width: 40px;
  height: 40px;
  border-radius: 50px;
}


li#threefour:hover svg.feather.feather-user {
  /* background-color: rgba(0, 0, 255, 0.272); */
  left: 1px;
  position: relative;
  /* border-radius: 50px; */
}

.logoopen {
  position: fixed;
  top: 23px;
  z-index: 9999999999;
  left: 110px;
  border-bottom: 1px solid wheat;
  height: 43px;
  /* border-bottom: 1px solid wheat; */
}



.card {
  /* border: none;
    border-radius: 11px;
    font-size: 13px;
    box-shadow: 1px 1px 5px 1px #1612121f;
    padding: 0px 10px;
    box-shadow: rgb(50 50 93 / 18%) -4px -6px 100px -20px, rgb(0 0 0 / 0%) 0px 20px 20px -30px;
    border: 1px solid #0000001f; */

  border: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: 0px;
  -webkit-box-shadow: 0 0 20px rgba(8, 21, 66, 0.05);
  box-shadow: 0 0 20px rgba(8, 21, 66, 0.05) !important;
}

.form-control {

  border: 1px solid #ced4da00 !important;

  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

g.logoopen {
  position: relative;
  /* left: 283px; */
  top: 22px;
}

.pre:hover.sidebar {
  width: 200px !important;
}

.pre {
  transition: 1ms ease-in;
}

.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 13px;
  font-weight: 400 !important;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  border-radius: .25rem;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out !important;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out !important;
  border-color: #eee !important;
  font-size: 13px;
  padding: 12px 15px;
}

.form-label {
  margin-bottom: .5rem;
  font-weight: 500 !important;
}

/* g.logoopen {
  position: relative;
  left: 43px;
  top: 22px;
} */
b,
strong {
  font-weight: 700 !important;
}

table {
  border: 1px solid #00000014 !important;

}


form.d-flex {
  display: flex;
  gap: 23px;
}

.ser {
  background: #F4F7FE;
  border-color: #F4F7FE;
  /* box-shadow: 1px 1px 5px 1px #00000024; */
  outline: none;
  background: #F4F7FE;
  border: none;
  height: 36px;
  border-radius: 6px;
  width: 505px;
  padding: 1px 2px 1px 47px;
}

svg.feather.feather-search {
  position: relative;
  left: 59px;
  top: 5px;
  background: none;
  stroke: black;
  fill: wheat;
  box-shadow: none;
}

nav#naving {
  display: flex;
  justify-content: flex-end;
}



.link2 {
  line-height: 31px;
  font-size: 14px;
  /* fill: #164194;
  stroke: #ffffff; */
  left: 11px;
  position: relative;
  display: flex;
  color: white;
  padding: 6px 6px;
  gap: 20px;
  width: 50px;
  transform: all 0.5s;
  cursor: pointer;
  top: 40px;
  text-decoration: none;
  font-size: 12px;
  padding-block: 14px !important;
  font-weight: 600;
}


table td {
  font-size: 13px !important;
  font-weight: 500 !important;
}

.mb-1.row {
  /* margin-top: -48px; */
}

table.table th,
table.table td {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

input#formBasicName {
  /* margin-top: -10px; */
  /* position: relative;
  top: -22px;
  width: 668px;
  left: -334px; */
}

table.table th {
  padding-top: 17px !important;
  padding-bottom: 18px !important;
  /* background: linear-gradient(170deg, hsl(227deg 74% 49% / 70%) 0%, hsl(220deg 74% 33% / 81%) 49%, hsl(226deg 74% 35% / 90%) 89%)!important; */
  /* color: white; */
  font-weight: 600 !important;
  font-size: 13px;
}

.pagination .page-item.active .page-link {
  color: #fff;
  background-color: #164194 !important;
  border-radius: 0.125rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: all 0.2s linear;
  border-radius: 4px !important;
}

/* .active  :hover{
  background-color: #16409481!important;
color: #164194!important;
} */

.bb {
  border-bottom: 1px solid transparent;
  /* box-shadow: 1px 1px 9px #00000040; */
  box-shadow: 1px 1px 20px 1px #50505029 !important;
  /* stroke: #164194!important; */
  /* fill: white!important; */
  width: 25px !important;
}

#new>div>div>div.card-body>div.justify-content-end.align-items-end>div {
  margin-top: -48px !important;
}

input#formBasicName {
  /* margin-top: -10px; */
  position: relative;
  /* top: -16px; */
}

svg.feather.feather-bell {
  stroke: #164194;
  fill: #16419424;
}


svg.feather.feather-settings {
  stroke: #164194;
  fill: #16419424;
}

svg.feather.feather-search {
  stroke: #164194 !important;
  fill: #16419424 !important;
}

svg.feather.feather-user {
  stroke: #ffffff;
  fill: #142b5700;
}

/* svg.feather {
  
  fill:  #164194;
  
  stroke:white;
  
} */

td {
  cursor: pointer;
}

.black12 {
  background-color: white;
}

.whitett {
  color: rgb(0, 0, 0);
  text-decoration: none;
  text-transform: capitalize;
  list-style: none;
  position: relative;
  top: 16px;
  font-size: 12px;
  padding-right: 24px;
  font-weight: 600;
}

.fontwhite {
  color: white;
}

.rtr {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 30px;
  height: 30px;
}

.navwe {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #00000036;
  height: 3.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

.ee {
  width: 4%;
  position: relative;
  top: 12px;
}

li {
  list-style: none;
}

.pagination .page-item.active .page-link {
  color: #fff;
  background-color: #4285f4;
  border-radius: 0.125rem;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  transition: all 0.2s linear;
  margin-top: -7px;
}

li.whitett.qq {
  width: 123px;
  background: #164194;
  color: white;
  text-align: center;
  padding: 6px;
  height: 36px;
  position: relative;
  top: 10px;
  border-radius: 3px;
}

.login {
  /* border: 2px solid ; */
  text-align: center;
  padding: 6px;
  width: 81px;
  font-size: 14px;
  height: 37px;
  position: relative;
  top: 11px;
  border-radius: 4px;
}

.ggfg {
  width: 600px;
  position: relative;
  top: 23px;
  border-radius: 5px;
}
.print-content{
  border-radius: 12px;
}
.ttdf {
  display: flex;
  justify-content: center;

}
.print-content {
         
  display: grid;
  grid-template-columns: repeat(2, 40mm);
  gap: 11mm;  


}

.barcodeomkar{
   height:27px;
  position:relative;
  top:10px;
  margin-top: 9px;
}
.rotate{
  rotate: 0deg;
  /* margin-top: 30px; */
  width: 103%;
  
}
.product-name {
  font-size: 7px;
  font-weight: 700;
}



.product-details {
  font-size: 8px;
  font-weight: 700;
}
.linehighter{
  line-height: 5px;

}
.neelamfontsize{
  font-size:13px!important;
}

@media print {
  .neelamfontsize{
    font-size:8px!important;
  }
  
  .rotate{
    rotate: 270deg;
    /* margin-top: 30px; */
    width: 103%;
    
  }
  .btnmedia{
    display: none;
  }
  .column {
    float: left;
    width: 48%;
    margin-left: 10px;
    /* padding: 80px; */
    height: 440px;
    break-inside: avoid;
    
    /* transform: rotate(270deg); */
    /* float: left;
    width: 372px;
    margin-left: 10px;
    padding: 80px;
    height: 496px; */
  }
  .spa{
    margin-top: -2px;
    width: 110%;
  }
 
  .page-break {
    page-break-before: always;
  }
  /* Clear floats after the columns */
  .Print:after {
    content: "";
    display: table;
    clear: both;
  }
}

.log {
  font-weight: 500;
}
span.eancodestyle {
    font-size: 7px !important;
}
.fixering{
  font-size: 5px !important;
    font-weight: 700 !important;
}


buttton.btn.lo {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  border: 2px solid #164194;
  font-weight: 600;
  color: #164194;
  transition: 0.5s;
}



buttton.btn.lo:hover {
  width: 100%;
  transition: 0.5s;

  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  border: 2px solid #164194;
  font-weight: 600;
  background-color: #16409452;
  transition: 0.5s;
  color: #164194;
  position: relative;
  top: -1px;
}

button.btn.er {
  width: 100%;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  background: linear-gradient(228deg, hsla(227, 74%, 49%, 1) 0%, hsla(220, 74%, 33%, 1) 49%, hsla(226, 74%, 35%, 1) 89%);
}



.log {
  /* font-weight: 500; */
  font-size: 18px;
}


table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  right: 16px;
  font-family: "Font Awesome\ 5 Free", sans-serif;
  font-size: 1rem;
  font-weight: 900;
  content: "\f0dd";
  color: rgb(255 0 0);
}
.table-row {
  cursor: pointer;
}

.sub-row {
  max-height: 0;
  overflow: hidden;
  transition: max-height 3s ease-out, opacity 3s ease-out;
  opacity: 0;
}

.sub-row.expanded {
  max-height: 100px; /* Adjust based on your content's height */
  opacity: 1;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
  right: 1em;
  font-family: "Font Awesome\ 5 Free", sans-serif;
  font-size: 1rem;
  font-weight: 900;
  content: "\f0de";
  color: #164194;
}

.children {
  transition: 0.5s;
}


/* .link2:hover {
  background: white;
  width: 58px;
  stroke-width: 113px;
  z-index: 99999999;
  /* height: 49px; */
/* border-radius: 8px; */
/* padding-block: 1px; */
/* margin-top: 4px; */
/* box-shadow: rgba(85, 29, 238, 0.4) 5px 5px, rgba(240, 46, 170, 0.3) 10px 10px, rgba(240, 46, 170, 0.2) 15px 15px, rgba(240, 46, 170, 0.1) 20px 20px, rgba(240, 46, 170, 0.05) 25px 25px;  top: 13px; */
/* padding-block: 1px; */





/* xenop25875@bookspre.com */




/* button.btn.btn-primary {
  background-color: rgb(63, 79, 111) !important;
  font-weight: 700;
  border-radius: 8px;
} */


th {
  border: none;
  background: #F3F6F9 !important;
  color: #000000;
}

.flex {
  display: flex;
  justify-content: space-between;
}


ul.pagination {
  gap: 15px;
  font-size: 11px;
  cursor: pointer;
  user-select: none;
}

#new>div>div.card-body>div>div.pagination>ul>li:nth-child(2) {
  display: none;
}

li {
  transition: 0.8s;
  cursor: pointer;
}

li.newway {
  font-weight: bold;
  background: #163F93;
  color: white;
  height: 29px;
  width: 34px;
  text-align: center;
  padding: 5px;
  position: relative;
  top: -5px;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

td.datatabletd {
  line-height: 27px;
}

.thh {
  width: 184px;
  height: 34px;
  font-size: 11px;
  font-weight: 600;
  position: relative;
  top: -12px;
  border-radius: 6px;
}

span.add {
  position: relative;
  top: -6px;
}

.forentriesandaddbutton {
  /* display: flex; */
  /* justify-content: space-between; */
}

.the:hover {
  background: #adadad0f;
}

.card-header.flex {
  height: 3pc;
  background: #80808005;
  border-color: #8080802b;
}

/* .newselect{
  position: relative;
      top: -8px;
      border-color: #80808040;
      width: 51px;
      border-radius: 6px;
  } */
.newselect {
  position: relative;
  top: -8px;
  border-color: #80808040;
  width: 53px;
  border-radius: 6px;
  height: 37px;
}

.Entries {
  display: flex;
  gap: 11px;
}


th {
  cursor: pointer;
  user-select: none;
}

.forentriesandaddbutton {
  display: flex;
  justify-content: space-between;
}

.bottole {
  display: flex;
  gap: 10px;
}

.up2 {
  position: relative;
  top: -8px;
}

.pk {
  width: 109px;
  color: #ffffff;
  background: linear-gradient(256deg, rgba(0, 166, 141, 1) 0%, rgb(0 166 141) 35%, rgb(0 166 141 / 58%) 100%);
  font-weight: bold;
  border: none;
  border-radius: 5px;
  box-shadow: 1px 1px 4px 1px #0000005c;
  font-weight: 500 !important;
  height: 32px;
  position: relative;
  top: -5px;
  font-weight: 600 !important;
}

.whitefont {
  color: #fff !important;
}

.timer {
  display: flex;
  flex-direction: column;
  gap: 76px;
}

.yty {
  display: flex;
  flex-direction: row;
}

.card.yty {
  border: 1px solid;
  width: 178px;
  border-color: #00000066;
}

button.btn.btn-sm.btm-primary {
  background: #164194;
  color: white;
  font-weight: 700;
  /* border-radius: 7px; */
  padding: 12px;
}

.flexleft {
  display: flex;
  justify-content: space-between;
}


.card.yty {
  display: none;
  border: 1px solid;
  width: 181px;
  border-color: #00000066;
  height: 183px;
  border-top-left-radius: 12px;
  /* border-radius: 7px; */
  border-top-right-radius: 26px;
  background: linear-gradient(228deg, hsla(227, 74%, 49%, 1) 0%, hsla(220, 74%, 33%, 1) 49%, hsla(226, 74%, 35%, 1) 89%) !important;
  border-radius: 15px;
  position: absolute;
  left: 6px;
  /* top: 452px; */
  transition: 2s;
}

.fontweight {
  font-weight: 600;
}

.ert {
  display: flex;
  justify-content: center;
  border-color: white;
  border: 5px solid white;
  border-radius: 181px;
  height: 73px;
  position: relative;
  top: -18px;
  width: 72px;
  background: linear-gradient(228deg, hsla(227, 74%, 49%, 1) 0%, hsla(220, 74%, 33%, 1) 49%, hsla(226, 74%, 35%, 1) 89%) !important;
  left: -3px;
  display: flex;
  justify-content: center;
  padding-top: 15px;
  font-size: 20px;
}

.FONT11 {
  font-size: 11px;
  FONT-WEIGHT: 600;
}

ul li .btn {
  width: 153px;
  border-radius: 7px !important;
  position: relative;
  left: -114px;
  top: 77px;
  background: linear-gradient(170deg, hsl(227deg 74% 49% / 70%) 0%, hsl(220deg 74% 33% / 81%) 49%, hsl(226deg 74% 35% / 90%) 89%) !important;
  box-shadow: 9px 9px 20px 3px #0000004f;
}

/* ul li .btn {
  width: 153px;
  border-radius: 27px!important;
  position: relative;
  left: -114px;
  top: 95px;
  background: linear-gradient(170deg, hsl(227deg 74% 49% / 70%) 0%, hsl(220deg 74% 33% / 81%) 49%, hsl(226deg 74% 35% / 90%) 89%)!important;
} */
.erer {
  position: relative;
  top: -30px;
  left: -47px;
  line-height: 4px;
}

.green1 {
  color: rgb(255, 255, 255);
  font-weight: 600;

}

.red1 {
  color: rgb(255, 255, 255);
  font-weight: 600;
}

.two {
  color: #23ff23;
  font-weight: 600;

}

span.five {
  color: #ff8d8d;
}



@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@600&display=swap');







a.btn.btn-sm.btn-primary {
  width: 109px;
  color: #ffffff;
  background: linear-gradient(356deg, rgba(0, 166, 141, 1) 0%, rgb(0 166 141) 35%, rgb(0 166 141 / 58%) 100%);
  font-weight: bold;
  border: none;
  border-radius: 5px;
  box-shadow: 1px 1px 4px 1px #0000005c;
  font-weight: 500 !important;
  height: 32px;
  position: relative;
  top: -5px;
  font-weight: 700 !important;
}







tbody+thead {
  display: none;
}

















/* .btn{
  width: 100%;
} */













svg.color {
  /* display: none; */
}

nav.navbar {
  /* box-shadow: 1px 1px 12px #00000045; */
}

.sidebar {
  /* box-shadow: 1px 1px 4px #00000045; */
  /* box-shadow: 1px 1px 8px 1px #0000009c; */
}


/* .form-control{
    background: #fff;
    font-size: 17px;
    border-radius: 50px;
    padding: 6px 4px 9px 35px;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 10px;
  } */










/* input:-internal-autofill-selected {
 
  background-image: none !important;
  background-color:white!important;
 
} */




















th {}







.link {
  margin-top: -7px;
  line-height: 31px;
  font-size: 14px;
  fill: #164194;
  stroke: #ffffff;
  left: 11px;
  position: relative;
  display: flex;
  color: rgb(0 0 0 / 68%);
  padding: 6px 6px;
  gap: 20px;
  width: 182px;
  transform: all 0.5s;
  cursor: pointer;
  top: 40px;
  text-decoration: none;
  font-size: 12px;
  font-weight: 600 !important;
}









svg.feather {
  position: relative;
  height: 27px;
  left: -5px;
  padding: 3px;
  border-radius: 4px;
  top: 0px;
  width: 30px;
  stroke: white;
  font-variant: historical-ligatures;
  fill: #164194;
  box-shadow: 1px 1px 20px 1px #0000ff29;
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
  border-radius: 5px;
}








.card {
  /* border-top-left-radius: 23px; */
  /* border-bottom-right-r        mmmmmmmmmmmmmmmmmmmm                                                                                                                                                                                                                                                                                                                                                                                                                                                                   ṁadius: 23px; */
}

.active {
  /* font-weight: bolder; */
  background-color: #F3F6F9;
  color: rgb(0 0 0) !IMPORTANT;
  transform: all 0.5s;
  border-radius: 9px;
  color: #000000;
  fill: #164194;
  stroke: white;
  font-weight: 700 !important;
}



.snackbar_circle {
  /* background-color: #ebe9e9; */
  border-radius: 20px;
  padding: 10px 12px;
}

.checkmark {
  font-family: arial;
  font-weight: bold;
  -ms-transform: scaleX(-1) rotate(-40deg);
  -webkit-transform: scaleX(-1) rotate(-40deg);
  transform: scaleX(-1) rotate(-40deg);
  color: #63BA3D;
  display: inline-block;
  font-size: 28px;
  margin-top: 40px;
}

/* .row{
    gap: 10px;
  } */
.card1 {
  /* border: 5px solid rgb(238, 238, 238); */
  width: 100%;
  height: auto;
  background: #f8f8f8;
  margin-left: 10px;
  padding: 10px;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: rgba(10, 9, 9, 0.1) 0px 10px 50px;
}

svg.box-icon {
  fill: #164194;
  height: 17px;
}

.card1 p3 {

  font-size: 30px;
  color: #494848;
  font-weight: bold;
  line-height: 30px;
  padding-left: 10px;
  margin-top: 10px;
  display: inline-block;

}

.card1 p3 span {
  font-size: 16px;
  font-weight: 400 !important;
  /* color: #bcbfc3; */
  color: #000000;
}




.card3 {
  border-radius: 19px;
  border: none;
  background: rgb(248, 248, 248);

  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}








@media only screen and (max-width: 1580px) {
  .product2 {
    /* background-color: rgb(216, 22, 64); */
    /* width: 110%; */
    /* overflow: scroll; */
    position: relative;
    /* width: 90%; */
    /* height: 95vh; */
  }
}



/* thead{ */
/* background: radial-gradient(#023af9, #5b71fb); */
/* background: radial-gradient(#4C90D2, #0081ff); */
/* color: #4C90D2; */





/* } */

h4 {
  font-size: 22px;
  padding: 10px 0px;
  /* text-align: center; */
  color: black;
  border-bottom: 1px solid rgb(0, 0, 0);
  /* background-color: white; */
  font-weight: bold;
}

/* Track */














/* .form-label {
  font-style: sans;
  font-weight: 500!important;
  margin-bottom: 0.5rem;
  font-size: 12px!important;
  color: #00000073;
  margin-top: 12px!important;
} */

.form-label {
  font-style: sans;
  font-weight: 500 !important;
  margin-bottom: 0.5rem;
  font-size: 13px !important;
  color: #000000de;
  margin-top: 15px !important;
}
















@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;900&family=Overpass:ital,wght@0,100;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,700&display=swap');


#root>div>div>div.cccc>div.hhh>div.children>div>div.card-body>table>div:nth-child(2)>div>h2>button>tr {
  font-size: 13px;
}

.sc-hLBbgP.sc-eDvSVe.sc-ipEyDJ.jshaBn.bQdYbR.dlSNBX.rdt_TableCol {
  background: #164194;
  color: white;
}

























































































.form-label {
  margin-bottom: .5rem;
  font-weight: 600 !important;
}

/* g.logoopen {
  position: relative;
  left: 43px;
  top: 22px;
} */

a.btn.btn-sm.ertere {
  width: 150px;
  position: relative;
  top: 42px;
  height: 30px;
  background-color:#4163A7;
color: white;
font-weight: 500;
border-radius: 8px;

}
.img.image-gallery-image{
width:auto!important;
height: 40px;
}
span.loght {
  font-weight: 400;
}
a.btn.btn-sm.ertere:hover {
   background-color:#4163a7bf;
    font-weight: 500;
}
table {
  border: 1px solid #00000027 !important;

}
.tablelight{
  border: 1px solid #0000001c !important;
}
.borderwhite {
  border-left: 1px solid black;
}

.bgw {
  background-color: white !important;
}

.ogleft {
  text-align: left;
}

.gstin {
  font-size: 10px;
  font-weight: 500 !important;
}


.IGSTNET {
  height: 1px;
}



















/* .active  :hover{
  background-color: #16409481!important;
color: #164194!important;
} */













/* svg.feather {
  
  fill:  #164194;
  
  stroke:white;
  
} */








.ee {
  width: 8%;
  position: relative;
  top: 12px;
}








.ggfg {
  width: 600px;
  position: relative;
  top: 23px;
  border-radius: 5px;
}







buttton.btn.er {
  width: 100%;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  background: linear-gradient(228deg, hsla(227, 74%, 49%, 1) 0%, hsla(220, 74%, 33%, 1) 49%, hsla(226, 74%, 35%, 1) 89%);
}










.link2 {
  /* transition: 1.1s; */
}


/* .link2:hover {
  background: white;
  width: 58px;
  stroke-width: 113px;
  z-index: 99999999;
  /* height: 49px; */
/* border-radius: 8px; */
/* padding-block: 1px; */
/* margin-top: 4px; */
/* box-shadow: rgba(85, 29, 238, 0.4) 5px 5px, rgba(240, 46, 170, 0.3) 10px 10px, rgba(240, 46, 170, 0.2) 15px 15px, rgba(240, 46, 170, 0.1) 20px 20px, rgba(240, 46, 170, 0.05) 25px 25px;  top: 13px; */
/* padding-block: 1px; */


.link2:hover {
  background: white;
  width: 58px;
  stroke-width: 113px;
  z-index: 99999999;
  /* height: 49px; */
  border-radius: 8px;
  /* padding-block: 1px; */
  /* margin-top: 4px; */
  /* box-shadow: rgb(0 6 255 / 84%) 5px 5px, rgb(245 129 4 / 68%) 10px 10px, rgb(221 83 28 / 44%) 15px 15px, rgba(240, 46, 170, 0.1) 20px 20px, rgba(240, 46, 170, 0.05) 25px 25px; */
  /* top: 13px; */
  /* padding-block: 1px; */
}







.newClass {

  background: white;
  width: 58px;
  stroke-width: 113px;
  z-index: 99999999;
  /* height: 49px; */
  border-radius: 8px;
  /* padding-block: 1px; */
  /* margin-top: 4px; */
  /* box-shadow: rgb(0 6 255 / 84%) 5px 5px, rgb(245 129 4 / 68%) 10px 10px, rgb(221 83 28 / 44%) 15px 15px, rgba(240, 46, 170, 0.1) 20px 20px, rgba(240, 46, 170, 0.05) 25px 25px; */
  /* top: 13px; */
  /* padding-block: 1px; */

}

/* button.btn.btn-primary {
  background-color: rgb(63, 79, 111) !important;
  font-weight: 700;
  border-radius: 8px;
} */


















/* .newselect{
  position: relative;
      top: -8px;
      border-color: #80808040;
      width: 51px;
      border-radius: 6px;
  } */



th {
  cursor: pointer;
}



.pk {
  width: 109px;
  color: #ffffff;
  background: linear-gradient(256deg, rgba(0, 166, 141, 1) 0%, rgb(0 166 141) 35%, rgb(0 166 141 / 58%) 100%);
  font-weight: bold;
  border: none;
  border-radius: 5px;
  box-shadow: 1px 1px 4px 1px #0000005c;
  font-weight: 500 !important;
  height: 32px;
  position: relative;
  top: -5px;
  font-weight: 600 !important;
  display: grid;
  align-items: center;
  text-align: center;
}


.fontred {
  color: red;
}

/* #new > div > div.card-body > div > div:nth-child(10) > div:nth-child(1) > label:nth-child(3){
  display: none;
} */
/* #new > div > div.card-body > div > div:nth-child(10) > div:nth-child(2) > label:nth-child(3){
  display: none;

} */
.leaflet-container {
  height: 800px;
  width: 100%;
}

.leaflet-container.leaflet-touch.leaflet-fade-anim.leaflet-grab.leaflet-touch-drag.leaflet-touch-zoom {
  position: fixed;
  height: 216%;
  outline-style: none;
}

.borderight {
  border-right: 1px solid black;
}

.hidden {
  display: none !important;
}

.fullWidth {
  width: 100% !important;
  margin-left: 1px !important;
  padding: 1px !important;
}


.border2 {
  border-bottom: 1px solid black;
}

.center {
  text-align: center;
}

.border1 {
  border: 1px solid black;

}

.borderl {
  border-left: 1px solid black;
}

font {
  font-size: 12px;
  font-weight: 500;
  padding-left: 9px;
}

font1 {
  font-size: 12px;
  font-weight: 500;
  padding-left: 7px;
}

.ertrt {
  width: 109%;
  display: block;
}

.fontprint {
  font-size: 14px;
  font-weight: 500;
}

.fontprint1 {
  font-size: 13px;
  font-weight: 500;
}

.probook {
  line-height: 16px;
  padding-block: 7px;
}


th.thback {
  background: rgba(255, 255, 255, 0) !important;
  border: 1px solid black;
}

.white {
  color: rgba(255, 255, 255, 0);
}

.newhite {
  background-color: rgba(255, 255, 255, 0) !important;
  color: rgba(255, 255, 255, 0);
  border: 1px solid black;
}

td.thback1 {
  line-height: 28px;
  border-left: 1px solid black;
}

td.thback34 {
  line-height: 79px;
  border-left: 1px solid black;
}


.bordert {
  border-top: 1px solid;
}

.borderbottom {
  border-bottom: 1px solid black;
}

.left2 {
  text-align: right;
  /* display: flex;
  align-items:flex-end; */
}

.top3 {
  /* position: fixed; */
  /* display: block; */
  /* width: 99.8%; */
  /* top: 1px; */
  background-color: white;
  border: 1px solid;
}

p.heymarker {
  /* position: fixed; */
  background: red;
  width: 100%;
}


@media print {

  .rotate-print {
    /* transform: rotate(90deg); */
    /* transform-origin: top left; */
    /* width: 100vh; Adjust width to fit rotated content */
    /* height: 100vw; Adjust height to fit rotated content */
    /* position: absolute; Ensure it does not affect other content */
    /* top: 0; */
    /* left: 0; */
  }
  *{
    margin: 0px;
    padding:0px;
  }
  .noprint {
    visibility: visible;
  }

  .fontprint {
    /* color: pink;
    background-color: red; */
  }

  .top3 {
    position: fixed;
    display: block;
    width: 99.5%;
    top: 1px;
    background-color: white;
    border-left: 1px solid;
  }

  p.thback.props {
    margin-top: 281px;
  }

  /* .form-label.tester {
    font-size: 5px !important;
    position: relative;
    top: 10px;
    left: 4px;
} */
}


.newtag {
  background-color: pink;
}


tr.thback.props {
  margin-top: 20px !important;
}

.form-label.tester {
  position: relative;
  top: -22px;
  font-size: 7px !important;
}
/* .form-label.tester {
  font-size: 5px !important;
  position: relative;
  top: 10px;
  left: 4px;
} */
/* .col-6.borderl.border2 {
  width: 48.9%;
  border: 1px solid;
} */


.newywer {
  width: 49.1%;
  /* border: 1px solid; */
}



.prmotee {
  height: 53px;
  width: 5.25em !important;
  transform-origin: center;
  animation: rotate4 2s linear infinite;


}



/* 
 .newClass {
  fill: none;
  stroke: hsl(214, 97%, 59%);
  stroke-width: 2;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 1.5s ease-in-out infinite;
 } */

.newClass123 {
  fill: none;
  stroke: hsl(214, 97%, 59%);
  stroke-width: 2;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 1.5s ease-in-out infinite;
  transition: 0.5s;
}

table.table.table-hover.dataTable {
  border: 1px solid #00000014 !important;
}

@keyframes rotate4 {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash4 {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dashoffset: -125px;
  }
}


#new>div>table>tbody>tr:nth-child(18) {
  border-bottom: 1px solid black;

}



.backgroud {
  background-color: red;
}

p.thback.props11 {
  page-break-after: always;
}

p.thback {
  margin-top: 143px;
}


.python {
  page-break-after: always;
}


@media print {
  .print-page {
    page-break-inside: avoid;
  }


}

.red-row {
  background-color: red;
}


.top3 {
  display: block;
}


.rede {
  background-color: rgba(255, 255, 255, 0);
  position: relative;
  text-align: none;
  border: 1xp;
  border: 0px solid white;
  top: 0px;
}


.white11 {
  color: rgba(255, 255, 255, 0) !important;
  background-color: rgba(255, 255, 255, 0) !important;

}


.theee {
  border: 1px solid black;

}




@media print {

  .jpmorgon {
    page-break-after: always;
    /* page-break-before: always; */
  }
}

@media print {
  .footerte {
    /* position: fixed;
    bottom: 0;
    left: 0;
    /* width: 100%; */

    /* text-align: center; */
    /* padding: 10px; */
  }


  .sidebar {
    display: none;
  }


  .qw.er.pre {
    display: none;
  }

  .closemenu {
    display: none;
  }

  .gtg {
    border-bottom: none;
  }

}

.padding15 {
  padding-left: 15px !important;
}



.learnt {
  width: 144px;
  height: 122px;
  position: relative;
  left: 31px;
  top: 4px;
}

td.ryrree {
  line-height: 14px;
  text-align: justify;
  padding-left: 23px;
}

kk {
  font-size: 9px;
}




h1.po {
  position: absolute;
  bottom: 1px;
}



.right2 {
  text-align: left;
}

.smallingfont {
  font-size: 8px;
}

.right {
  text-align: right;
}

.space22 {
  padding-left: 10px;
}

.friction {
  display: flex;
  /* justify-content: space-between; */
  /* gap: 345px; */
}

.goooods {
  line-height: 1px;
}

.ggggggg {
  line-height: 0px !important;
  position: relative;
  top: 8px;
}

h1.para {
  height: auto;
  width: 280px;
  font-size: 13px;
  font-weight: 500 !important;
}

.rty3 {
  border-bottom: 2px solid;
  height: 125px;
  border-left: 0px solid;
}

.fizer {
  width: 294px;
}

.col.rty33 {
  height: 100px;
  border-bottom: 2px solid black;
  border-left: 0.1px solid black;
}

.borderhard {
  border: 1px solid black
}

.smallheight {
  height: 72px;
}

table.table.thrtable {
  border: 1px solid #0000000d !important;
}


.ertrt {
  border: 1px solid;
  height: 89px;
  position: relative;
  top: 9px;
  left: 1px;
  width: 668px;
}

.orgindisplay {
  display: flex;
  justify-content: space-between;
}

.size9 {
  font-size: 9px !important;
}

.size91 {
  font-size: 1px !important;
}

.size911 {
  font-size: 9px !important;
}

.sizeropt {
  font-size: 11px !important;
}

.border {
  border: 1px solid black !important;
}

.fontsize12 {
  font-size: 14px;
}


.ertrt12 {
  border: 1px solid;
  /* height: 89px; */
  position: relative;
  /* top: 9px; */
  left: -13px;
  width: 1288px;
  display: block;

}

td.thback1.rtrtetr.sizeropt {
  text-align: left;
  line-height: 10px;
  padding: 8px;
}

frf {
  font-size: 12px;
  font-weight: 700;
}

a.prox.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 39px;
  width: 99px;
  background: #164194c9;
}

a.delete.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 39px;
  width: 99px;
  background: #d14b57;
}

.shimmer {
  position: relative;
  overflow: hidden;
  background: white;
  top: -15px;
  margin-block: 9px;
  color: white !important;
}

.shimmer::before {
  color: white !important;
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 70%;
  height: 100%;
  background: linear-gradient(to right, transparent 0%, #F3F2F2 25%, transparent 50%, #F3F2F2 75%, transparent 100%);
  animation: shimmer 1s infinite linear;
}

.shimmer p3 {
  color: white;
}

.shimmer span {
  display: none;
}

.shimmer .box-icon {
  display: none;
}

.shimmer img {
  display: none;
}

.shimmer .datatabletd svg.feather.feather-eye {
  stroke: white;
  fill: white;
}

@keyframes shimmer {
  0% {
    left: -100%;
  }

  100% {
    left: 100%;
  }
}

.shimmer1 {
  position: relative;
  overflow: hidden;

  margin-block: 9px;
  border-radius: 12px;
  stroke: #1C44BC !important;
  fill: #1C44BC !important;
}

.shimmer1 svg {
  stroke: #1D44BD;
}

.shimmer1::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, transparent 0%, #4f68ba 25%, transparent 50%, #4f68ba 75%, transparent 100%);
  animation: shimmer 1s infinite linear;
}

@keyframes shimmer1 {
  0% {
    left: -100%;
  }

  100% {
    left: 100%;
  }
}


.loader {
  width: 48px;
  height: 48px;
  margin: auto;
  position: relative;
}

.loader:before {
  content: '';
  width: 48px;
  height: 5px;
  background: #999;
  position: absolute;
  top: 60px;
  left: 0;
  border-radius: 50%;
  animation: shadow324 0.5s linear infinite;
}

.loader:after {
  content: '';
  width: 100%;
  height: 100%;
  background: linear-gradient(228deg, hsla(227, 74%, 49%, 1) 0%, hsla(220, 74%, 33%, 1) 49%, hsla(226, 74%, 35%, 1) 89%) !important;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  animation: jump7456 0.5s linear infinite;
}

@keyframes jump7456 {
  15% {
    border-bottom-right-radius: 3px;
  }

  25% {
    transform: translateY(9px) rotate(22.5deg);
  }

  50% {
    transform: translateY(18px) scale(1, .9) rotate(45deg);
    border-bottom-right-radius: 40px;
  }

  75% {
    transform: translateY(9px) rotate(67.5deg);
  }

  100% {
    transform: translateY(0) rotate(90deg);
  }
}

@keyframes shadow324 {

  0%,
  100% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1);
  }
}

.pilt {
  display: none;
  width: 103%;
  height: 91vh;
  background: white;
  justify-content: center;
}


.displaynone {
  display: none;
}

/* .card.aos-init.aos-animate {
  position: relative;
  top: -49px;
} */

div.notification-hub {
  background: #f9f9f9;
  border-radius: 4px;
  border-top-right-radius: 0;
  box-shadow: 0 2px 3px 0 #bbb;
  color: #333;
  /* height: 80vh; */
  right: 0;
  position: absolute;
  text-align: right;
  top: 100%;
  width: 50vh;
}

.notification-hub header {
  padding: 18px;
  text-align: left;
  color: white;
  font-weight: 600;
  border-bottom: 1px solid black;
  background-color: #163F93;
}


.notification-hub ul {
  border-top: 1px solid #ddd;
  text-align: left;
}

.notification-hub li {
  border-bottom: 1px solid #ddd;
  padding: 15px;
  margin-left: -30px;
}




.loader12 {
  border: 4px solid rgba(0, 0, 0, .1);
  border-left-color: transparent;
  border-radius: 50%;
}

.loader12 {
  border: 4px solid rgba(0, 0, 0, .1);
  border-left-color: transparent;
  width: 36px;
  height: 36px;
}

.scrolling {
  overflow: scroll;
  height: 300px;
}

.loader12 {
  border: 4px solid rgba(0, 0, 0, .1);
  border-left-color: transparent;
  width: 36px;
  height: 36px;
  animation: spin89345 1s linear infinite;
}

@keyframes spin89345 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}



span.mastersert {
  color: white;
  font-size: 11px;
  position: relative;
  top: 31px;
  left: 7px;
  font-weight: 500;
}

span.Purchaseing {
  color: white;
  font-size: 11px;
  position: relative;
  top: 31px;
  left: 3px;
  font-weight: 500;
}

span.mastersert.lefttt {
  position: relative;
  /* left: 1p; */
  left: 12px;
}


.fftt {
  display: flex;
  flex-direction: column;
}

span.msl {
  color: white;
  font-size: 11px;
  position: relative;
  top: 31px;
  left: 17px;
  font-weight: 500;
}

span.sales {
  color: white;
  font-size: 11px;
  position: relative;
  top: 31px;
  left: 13px;
  font-weight: 500;
}


.convertbutton{
  width: 95%;
}
.ulli .liul::marker {
  content: '✓';
  color: green;
}
.uli .liu::marker {
  content: "x";
  color: red;
}

.tomforgrandtotal{
  text-align: right;
  font-size: 17px;
  background: #F3F6F9;
  height: 47px;
  position: relative;
  top: 2px;
  padding: 10px;
font-weight: 500;
 }

 .profilestaff {    text-transform: capitalize;

  background: #1B43B6;
  height: 36px;
  color: #ffffff;
  width: 37px;
  border-end-end-radius: 16px;
}

.newcardingforstaff {
  display: flex;
  flex-direction: row;
  gap: 10px;
  background: #1b43b645;
}

p.profilename {
  position: relative;
  top: 10px;
  font-weight: 600;
}

.roless {
  position: relative;
  top: 10px;
  font-weight: 300;
}

.ogcol3 {
  padding: 8px;
}

.crossButton {
  background-color: transparent;
  border: none;
  color: #000;
  font-size: 20px;
  cursor: pointer;
  text-align: left;
  /* position: absolute; */
  /* left: 299px; */
  font-size: 26px;
}

.crossButton:hover {
  color: red;
}
label.form-label.fgffg {
  background: #C1CCEB;
  padding: 12px;
  border-radius: 5px;
  text-align: center;
}

.imgtag{
  position: relative;
    left: 33px;
    top: 14px;
    border-radius: 12px;
}

button.dfsdf.btn.btn-primary {
  width: auto;
}

.small {
  font-size: 12px;
  font-weight: 500;
}


.borderbottom12{
  border-bottom: 1px solid;
}

.hr3{
  width: 100%;
    position: relative;
    /* left: -5px; */
    border: 1px solid black;
    color: black;
}

.tyt{
  border-right: 1px solid;
    height: 58px;
    border-top: 1px solid;
    position: relative;
    border-bottom: 1px solid;
    left: 3px;
}

.tyt1{
  /* border-right: 1px solid; */
    height: 58px;
    border-top: 1px solid;
    position: relative;
    border-bottom: 1px solid;
    /* left: 3px; */
}

.border-left {
  border-left: 1px solid black; /* You can adjust the style as needed */
}

.border-left {
  border-left: 1px solid black;
}

.border-top {
  border-top: 2px solid black;
}
 
span.finderhelp {
  position: relative;
  margin-left: 2px;
  color: white;
}

span.findhelpin {
  /* margin-left: 10px; */
  color: white;
}

.feifdf{
  border-top: 1px solid;
  border-right: 1px solid;
  padding-left: 16px;
  border-bottom: 1px solid;
}
tyt {
  display: flex;
  justify-content: center;
  font-weight: 700;
}
yy {
  font-weight: 700;
}



mym {
  font-size: 12px;
  font-weight: 700;
}

.barcodeshail {
  position: relative;
  top: -31px;
  /* object-position: 91% 29%; */
  background: none;
  height: 35px;
  width: 218px;
  left: 14px;
  z-index: 12;
}

.yyzindex{
  position: relative;
  z-index: 9999;
}

.ontsize{
  position: relative;
  top: 15px;
  left: -9px;
  position: relative;
  top: 10px;
}

erwe {
  position: absolute;
  margin-top: -38px;
  z-index: 9999;
  font-weight: 700;
  font-size: 12px;
  margin-left: 74px;
  text-transform: capitalize;
  /* font-size: 19px; */
  padding: 1px;
}

#issueText{
  transition: 1s!important;
}

.promote {
  color: #F27474;
  font-weight: 500;
}

.fixin{
  margin-left: 16px;
  font-weight: 600;
  color: #2939a4f7;
}

.fixin {
  transition: opacity 1.3s ease;
}

.fixin-enter {
  opacity: 0;
}

.fixin-enter-active {
  opacity: 1;
}

.fixin-exit {
  opacity: 1;
}

.fixin-exit-active {
  opacity: 0;
}

#Print > div > table > tbody:nth-child(1) > tr > th:nth-child(1){
  background-color: white !important;
  border-top: hidden;
  border-left: hidden;
  border-right: hidden;
  border-bottom: 1px solid black;
}
#Print > div > table > tbody:nth-child(1) > tr > th:nth-child(2){
  background-color: white !important;
  border-top: hidden;
  border-left: hidden;
  border-bottom: 1px solid black;
  border-right: hidden;
}
#Print > div > table > tbody:nth-child(1) > tr > th:nth-child(3){
  background-color: white !important;
  border-top: hidden;
  border-left: hidden;
  border-bottom: 1px solid black;
  border-right: hidden;
}
#Print > div > table > tbody:nth-child(1) > tr > th:nth-child(4){
  background-color: white !important;
  border-top: hidden;
  border-left: hidden;
  border-bottom: 1px solid black;
  border-right: hidden;
}
#Print > div > table > tbody:nth-child(1) > tr > th:nth-child(5){
  background-color: white !important;
  border-top: hidden;
  border-bottom: 1px solid black;
  border-left: hidden;
  border-right: hidden;
}
#Print > div > table > tbody > tr > td {
  border-left: hidden !important;
}
#Print > div > table > tbody > tr > td:nth-child(5) {
  border-right: hidden;
}




.forsvg {
  margin-top: -7px;
  line-height: 31px;
  font-size: 14px;
  fill: #164194;
  stroke: #ffffff;
  left: 11px;
  position: relative;
  display: flex;
  color: rgb(0 0 0 / 68%);
  padding: 6px 6px;
  gap: 20px;
  width: 40px;
  transform: all 0.5s;
  cursor: pointer;
  top: 40px;
  text-decoration: none;
  font-size: 12px;
  font-weight: 600 !important;
}
.sidebar22 {
  position: fixed;
  height: 100%;
  left: 1px;
  top: 0px;
  background: #fff;
  box-shadow: 0 0 20px rgba(8, 21, 66, 0.05) !important;
  z-index: 0;
}

.sidebar22 {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 10px;
  background:linear-gradient(228deg, hsla(227, 74%, 49%, 1) 0%, hsla(220, 74%, 33%, 1) 49%, hsla(226, 74%, 35%, 1) 89%) !important;
  top: 0px;  
  box-shadow: 0 0 20px rgba(8, 21, 66, 0.05) !important;
  color: black;
  height: 100vh;position: fixed;
  transition: all 0.5s;
}

.forsvg:hover {
  font-weight: bold;
  background-color: #e9e9e991;
  color: black;
  transform: all 0.5s;
  border-radius: 6px;
  color: black;
  stroke: #ffffff;
  fill: black;
}


.linemakrw {
  display: flex;
  justify-content: end;
  position: relative;
  /* left: 32px; */
}

.sbgicn{
height: 32px;
width: 25px;
background: #1F47CF;
width: 80px;
z-index: 0;
color: white;
border-radius: 5px;
padding: 4px;
font-weight: bolder;
stroke-width: 50;  transition: all 0.5s;

}
.timeline {
list-style: none;
padding: 0;
}

.timeline-item {
position: relative;
margin-bottom: 20px;
}

.timeline-dot {
width: 10px;
height: 10px;
background-color: #E54125;
border-radius: 50%;
position: absolute;
left: 10px;
top: 9px;
}

.timeline-content {
margin-left: 31px;
padding: 7px;
background-color: #fdfdfd;
border-radius: 5px;
font-size: 11px;
width: 162px;
display: flex;
flex-direction: column;
gap: 2px;
color: white;


background: rgba( 255, 255, 255, 0.1 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 11.5px );
-webkit-backdrop-filter: blur( 11.5px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
}
.timeline-item:not(:last-child)::before {
content: '';
position: absolute;
width: 2px; 
height: calc(100% + 10px);
background-color: wheat;  
left: 13px;  
top: 20px;  
}
.tytttt {
width: 85%;
display: flex;
color: white;
justify-content: center;
background: #1e46c8;
font-weight: 600;
height: 28px;
position: absolute;
height: 39px;
bottom: -3px;
z-index: 00;
border-top-left-radius: 15px;
border-top-right-radius: 14px;
}

.popo{
position: relative;
top: 6px;
left: -6px;
}
.flexr{
display: flex;
justify-content: right;
}

.sidebar22::-webkit-scrollbar {
width: 10px;  
}

.sidebar22::-webkit-scrollbar-track {
background: transparent;  
}

.sidebar22::-webkit-scrollbar-thumb {
background-color: rgba(0, 0, 0, 0.2); 
border-radius: 5px;  
}

.sidebar22::-webkit-scrollbar-thumb:hover {
background-color: rgba(0, 0, 0, 0.4); 
}


h6.retailer {
font-weight: 500;font-weight: 500;
font-size: 12px;
letter-spacing: 0px;font-weight: 500;
font-size: 12px;
letter-spacing: 0px;
}
c.staffin {
font-weight: 500;
}
p.staffin1 {
font-weight: 600;
}

.earphones {
width: 148px;
background: rgba(255, 255, 255, 0.25);
box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border-radius: 10px;
border: 1px solid rgba(255, 255, 255, 0.18);
color: white;
font-weight: bold;
padding: 9px;
font-size: 13px;
font-weight: 500;
text-align: center;
position: relative;
left: 35px;
top: 6px;
}


.timeline-item:last-child {
  padding-bottom: 58px;
}

.ended{
  color: #faf7f7;
    background: #4260A3;
    border-radius: 5px;
    height: 38px;
    box-shadow: 1px 1px 5px 2px #0000002b;
    font-weight: 600;
    font-size: 11px;
}
div.notification-hub1 {
  background: #f9f9f9;
  border-radius: 4px;
  border-top-right-radius: 0;
  box-shadow: 6px -3px 24px 2px #bbb;
  color: #333;
  /* height: 80vh; */
  right: 0;
  position: absolute;
  text-align: right;
  top: 130%;
  /* width: 30vh; */
  /* min-width: 30vh;
  max-width: 30vh; */
}
#navbarDropdownMenuAvatar > div > div > a:nth-child(2):hover{
  background-color: #f6f7f9;
  color: #1F47CF;
}
#navbarDropdownMenuAvatar > div > div > a:nth-child(3):hover{
  background-color: #f6f7f9;
  color: red;
}
div.notification-hub1:before {
   content: " ";
    position: absolute;
    background: #ffffff;
    width: 25px;
    height: 20px;
    top: -5px;
    left: 94%;
    margin-left: -15px;
    transform: rotate(45deg);
}



.rdw-inline-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: -83px!important;
  flex-wrap: wrap!important;
}

.rdw-dropdown-wrapper.rdw-block-dropdown {
  margin-top: 4px!important;
  border: none;
}

.rdw-editor-wrapper{
  border: 1px solid #0000003d;
}

.projection-table-pagination {
  display: flex;
  justify-content: space-between;
}

.prev-next-div {
  display: flex;
  gap:1rem;
}
.converted{
  color:green;

}
.opens{
  color:red;

}
.showOrHide{
  border: none;
  padding: 8px 5px;
  color: white;
  background: linear-gradient(170deg, hsl(227deg 74% 49% / 70%) 0%, hsl(220deg 74% 33% / 81%) 49%, hsl(226deg 74% 35% / 90%) 89%) !important;
  /* height: 30px; */
  font-size: 11px;
  border-radius: 5px;
  font-weight: 600;
}
.blurred {
  filter: blur(5px);
  pointer-events: none; /* Prevents interaction with blurred fields */
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.table-responsive-2 {
  overflow-x: scroll;
  max-width: 100vw !important;
  margin-bottom: 1rem;
  scroll-behavior: smooth;
}

.table-responsive-2::-webkit-scrollbar {
  height: 10px;
}

.table-responsive-2::-webkit-scrollbar-track {
  background: transparent;
  border: 1px solid black;
  border-radius: 10px;
}

.table-responsive-2::-webkit-scrollbar-thumb {
  width: 50px !important;
  background: linear-gradient(228deg, #2049d9 0%, #163f92 49%, #17369b 89%) !important;
  border-radius: 10px;
}
.projection-entry-report-table td {
  border: 2px solid black;
}

.projection-entry-report-table th {
  border: 2px solid black;
  border-top: 2px solid black;
}
.select-container .form-select {
  max-height: 150px; /* Adjust the height based on your preference */
  overflow-y: auto;  /* Enable vertical scrolling */
}


.everycenter{
  display:flex;
  justify-content: center;
  align-items: center;
}

.newcardsize{
  height: 500px;
  width: 300px;
}

.newcenterforprofile{
  /* padding: 20px; */
  text-align: initial;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}