@media print and (orientation:portrait) {

    body {
  
      font-size: 12pt;
    }
    .table{
        font-size: 12pt;
    }
  }

  .brb{
    border: 1px solid black!important;
  }

  #Print > div > table > tbody > tr > td {
    border-left:unset  !important;
}